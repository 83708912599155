export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'code',
    label: 'field.sectionUsedCode',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.sectionUsedName',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'itemCount',
    label: 'field.itemCount',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updateDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: "deletedAt",
    sortable: true,
    sortField: "deletedAt",
    label: "field.deletedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "deletedBy",
    label: "field.deletedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
